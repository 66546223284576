@mixin slider-dots {
    .slick-dots {
        bottom: -40px;
        li {
            button:before {
                font-size: 10px;
                width: 10px;
                height: 10px;
                content: "";
                border: solid 2px $primary;
                border-radius: 50%;
            }

            &.slick-active {
                button:before {
                    background: $primary;
                }
            }
        }
    }
}