.navigation-bar{
    background: #fff;
    padding: 0 15px;
    .navie{
        text-align: center;
        margin: 0 auto;
        // max-width: 100px;
        padding:20px 15px;
        cursor: pointer;
        font-family:"MuliBold";
        img{
            height: 40px;
            margin-bottom: 7px;
            margin: 0 auto;
        }
        div{
            font-size: 16px;
            font-weight: 600;
        }

        &:hover{
            background: linear-gradient(270deg, #fff 0%, #f1f1f1 100%);
        }

        @media only screen and (max-width: 767px) {

            padding: 20px 0;
            img{
                height: 30px;
            }
            div{
                font-size: 12px;
            }
        }

        @media only screen and (max-width: 450px) {
            img{
                height: 25px;
            }
        }
    }

    
}