// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

$white:    #fff !default;
$gray-75: #F3FCFF !default;
$gray-100: #f8f9fa !default;
$gray-120: #F1F1F1 !default;
$gray-125: #FAFAFA !default;
$gray-150: #F4F4F4 !default;
$gray-170: #F7F7F7 !default;
$gray-200: #9E9E9E !default;
$gray-225: #E9E9E9 !default;
$gray-250: #ababab !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-450: #cbcbcb !default;
$gray-460: #D1D1D1 !default;
$gray-500: #e1e1e1 !default; 
$gray-550: #A9A9A9 !default;
$gray-570: #ECECEC !default;
$gray-575: #8C8C8C !default;
$gray-600: #696969 !default;
$gray-650: #7A7A7A !default;
$gray-675: #7E7E7E !default;
$gray-700: #495057 !default;
$gray-750: #4F626E !default;
$gray-755: #ADADAD !default;
$gray-800: #343a40 !default;
$gray-900: #333333 !default;
$gray-910: #4A4A4A !default;
$black:    #000 !default;
$border-light: #EBEBEB !default;
$border-dark: #002114 !default;
$txt-secondary: #808080 !default;
$txt-light:#878787 !default;
$btn-disable-bg: #E5E5E5 !default;
$btn-disable-color: #A0A0A0 !default;
$grays: () !default;
$gray-1000: #F6F6F6 !default;


// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "150": $gray-150,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "550": $gray-550,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);


$blue:    #2153A1 !default;
$light-blue:lighten($blue, 2) !default;
$night-blue:#0B253A !default;
$indigo:  #222F42 !default;
$purple:  #6f42c1 !default;
$pink:    #C00D45 !default;
$red:    #FF4E5D !default;
$dark-red: #CB2129 !default;
$orange:  #F79534 !default;
$yellow:  #ffc107 !default;
$green: #00A057 !default;
$mint-green: #EDFFFA !default;
$teal:    #00E9B2 !default;
$cyan:    #00BBDD !default;
$link:    #113784 !default;
$light-gray: #EDEDED !default;
$dark-blue: #013D79 !default;
$fb-color: #3e599c !default;
$twitter-color:  #00acee !default;
$blue-500: #1A2148 !default;
$blue-800: #131038 !default;
$card-bg : #062347 !default;
$reflive-clr: #FF005A !default;
$refsocial-clr: $reflive-clr;
$refsocial-secondary-clr: #37003C !default;
$refpay-clr: #09AFDB !default;
$refpay-secondary-clr : #101243 !default;
$highlight-clr: #E5FBF5 !default;
$fav-color: #FAB800 !default;
$selected-highlight-clr: rgba(0, 255, 135, 0.25) !default;
$unselected-highlight-clr: rgba(255, 0, 0, 0.17) !default;

$progress-clr1 : rgba(0, 222, 162, 0.6) !default;
$progress-clr2 : rgba(255, 104, 103, 0.6) !default;
$progress-clr3 : rgba(141, 54, 217, 0.6) !default;
$progress-clr4 : rgba(88, 143, 225, 0.6) !default;
$progress-clr5 : rgba(91, 198, 214, 0.6) !default;
$progress-clr6 : rgba(255, 216, 76, 0.6) !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800,
    "night-blue": $night-blue,
    "fb-color": $fb-color,
  ),
  $colors
);

$primary: #03f5e7 !default;
// $primary:#40EDD0 !default;
// $secondary:     #023320 !default;
$secondary:     #00AF8B !default;
$ternary:     #00AF8B !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$gradient-clr1 : $ternary !default;
$gradient-clr2 : #00FFBA !default;

$purple : #37003C;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "link":       $link
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true !default;
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $light-gray !default;
$body-color:                $gray-900 !default;

// Links
//
// Style anchor elements.

$link-color:                theme-color("link") !default;
$link-decoration:           none !default;
// $link-hover-color:          darken($link-color, 15%) !default;
$link-hover-decoration:     underline !default;
$link-secondary-color:       $red!default;
// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xxs: 0px,
  xs: 200px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

// @include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           0px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

// $box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
// $box-shadow:                  0 .5rem 1rem rgba($black, .12) !default;
// $box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

$component-active-color:      $white !default;
// $component-active-bg:         theme-color("primary") !default;
$component-active-bg:         #333 !default;

$caret-width:                 .3em !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      MuliRegular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
$font-black : 'MuliBlack';
$font-bold : 'MuliBold';
$font-bold-italic : 'Muli-BoldItalic';
$font-italic : 'MuliItalic';
$font-light-italic : 'Muli-LightItalic';
$font-light : 'MuliLight';
$font-regular : 'MuliRegular';
$font-semibold : 'MuliSemiBold';
$font-semibold-italic : 'Muli-SemiBoldItalic';
$font-black-italic : 'Muli-BlackItalic';
$font-xtrabold : 'Muli-ExtraBold';
$font-xtrabold-italic : 'Muli-ExtraBoldItalic';

$font-saira-black : 'Sairacondensed-black';
$font-saira-bold : 'sairacondensed-bold';
$font-saira-extraBold : 'sairacondensed-extrabold';
$font-saira-extraLight : 'sairacondensed-extralight';
$font-saira-light : 'sairacondensed-light';
$font-saira-medium : 'sairacondensed-medium';
$font-saira-regular : 'sairacondensed-regular';
$font-saira-semiBold : 'sairacondensed-semibold';
$font-saira-thin : 'sairacondensed-thin';
$font-LemonMilk: 'LemonMilk';


// stylelint-enable value-keyword-case

$font-size-base:              0.875rem !default; // Assumes the browser default, typically `14px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;
$btn-font-size-base:          $font-size-sm!default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-black:           900 !default;


$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;


$spacer: 1rem !default;

$headings-margin-bottom:      ($spacer / 2) !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              inherit !default;



$dt-font-weight:              $font-weight-bold !default;

$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem !default;

$text-muted:                  $gray-600 !default;

$table-caption-color:         $text-muted !default;

$label-margin-bottom:                   .5rem !default;


$modal-border-radius:               15px !default;
$modal-content-bg:                  $white !default;
$modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width:        $border-width !default;
$modal-content-border-radius:       $modal-border-radius + 1.5px !default;
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;

$input-bg:                              $white !default;
$input-disabled-bg:                     $white !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding:               1rem !default;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       4rem !default;


$btn-border-width:            0px !default;


$input-btn-padding-y:         0.813rem !default;
$input-btn-padding-x:         0.813rem !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-line-height-lg:    $line-height-lg !default;

$input-btn-border-width:      $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-line-height:             $input-btn-line-height !default;

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-border-width:            0px !default;
// $btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color:     $gray-600 !default;

$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius-sm !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
