.main-footer {
    background-color: $primary;
    padding: 80px 30px;
    padding-bottom: 0;
    font-family: "CocoSharp-Light";


    .coming-soon-box{
        background-color: #fff;
        padding: 15px;
        text-align: center;
        max-width: 250px;
        img{
            max-width: 100%;
            width: 100px;
        }
        .txt-cs{
            font-size: 16px;
            font-weight: 800;
        }
    }

    .desktop-hidden{display: none;}
    .foot-head {
        font-size: 13px;
        font-weight: 800;
        font-family: "CocoSharp-Extrabold";
        padding: 10px 0;
        color: $purple;
    }

    .foot-link1 {
        padding-left: 0;
        list-style-type: none;
        font-family: "CocoSharp-Light";
        margin-bottom: 20px;
        li {
            margin-bottom: 5px;
            a {
                color: #000000;
                font-size: 14px;

                &:hover,
                &:focus {
                    color: $purple !important;
                }
            }
        }
    }

    .hdng2 {
        font-size: 13px;
        font-weight: 800;
        font-family: "CocoSharp-Extrabold";
        color: $purple;
        margin-bottom: 10px;
    }

    .foot-link2 {
        padding-left: 0;
        list-style-type: none;
        font-family: "CocoSharp-Light";
        margin-bottom: 20px;
        li {
            position: relative;
            margin-bottom: 5px;
            // padding-left: 10px;
            // &:before {
            //     content: "";
            //     position: absolute;
            //     left: 0;
            //     width: 4px;
            //     height: 4px;
            //     background-color: #000;
            //     border-radius: 50%;
            //     top: 9px;
            // }

            a {
                color: #000000;
                font-size: 14px;

                &:hover,
                &:focus {
                    color: $purple !important;
                }
            }
        }
    }

    .refsoc-logo {
        font-weight: 900;
        font-family: "CocoSharp-Extrabold";
        color: $purple;
        .ref-logo {
            max-width: 30px;
        }
    }

    .nav-row {
        margin-bottom: 60px;
    }

    .iframe-container {
        max-height: 200px;
        overflow-y: auto;
        max-width: 250px;
    }

    .ft-strip {
        border-top: solid 1px #45b9a5;
        font-size: 14px;
        margin-top: 20px;
        padding: 20px 10px;
        font-family: "geomanist-regular";
        color: #000;
    }

    .social-ics {
        list-style-type: none;
        padding-left: 0;
        li {
            margin-bottom: 7px;

            i {
                margin-right: 10px;
                font-size: 15px;
                width: 24px;
                display: inline-block;
                text-align: center;

                &.icon-logo-2{
                    font-size: 13px;
                }
            }
            img {
                height: 10px;
                margin-right: 10px;
            }
            a {
                color: #000;

                &:hover {
                    color: $purple !important;
                }
            }
        }
    }



    @media (max-width: 991px) {
        padding-top: 30px;
        .social-head {
            display: none;
        }

        .nav-row {
            margin-bottom: 30px;
        }

        .mob-accord {
            margin-bottom: 30px;
            .body {
                padding: 0 15px;
                display: none;
                transition: all ease 0.5s;
            }

            &.open {
                .foot-head {
                    &:after {
                        content: "-";
                    }
                }

                .body {
                    display: block;
                }
            }
        }
        .foot-head {
            background-color: #ffffffbf;
            padding: 10px;
            margin-bottom: 10px;
            letter-spacing: 1px;
            position: relative;
            &:after {
                content: "+";
                position: absolute;
                right: 10px;
                top: 4px;
                font-size: 20px;
            }
        }

        .social-ics {
            display: flex;
            justify-content: space-between;
            li {
                a {
                    font-size: 20px;
                    padding: 0 5px;
                }

                i {
                    margin-right: 0;
                }
                img {
                    margin-right: 0;
                    height: 17px;
                }
                span {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 767px) {

        .desktop-hidden{display: block;}
        .mobile-hidden{display: none;}
    }
}
