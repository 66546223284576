.contact-us-sec {
    background-color: #fff;
    min-height: 100vh;
    padding: 100px 15px;

    h1 {
        color: #1f2024;
        font-family: "CocoSharp-Bold";
        font-size: 48px;
        text-align: center;
        margin-bottom: 50px;
    }

    .form-box {
        border: 1px solid #d4d4d4;
        border-radius: 30px;
        padding: 50px;

        .form-group {
            label {
                color: #000000;
                font-family: "MuliRegular";
                font-size: 12px;
                font-weight: bold;
                display: block;
            }

            .form-control.type2 {
                border-radius: 4px !important;
                background-color: #e4e3e8;
                border: solid 1px transparent;

                &.error{
                    border: solid 1px red;
                }
            }

            textarea {
                height: 110px;
            }

            .form-select2 {
                .secondary__control {
                    background-color: #e4e3e8 !important;
                    height: 36px;

                    &:after {
                        content: "\e9a7";
                        font-family: "icomoon" !important;
                        margin-right: 10px;
                        font-size: 12px;
                        margin-top: 5px;
                    }
                }
            }
        }

        .contact-btn {
            height: 44px;
            width: 220px;
            border-radius: 22px;
            font-size: 16px;
            font-weight: bold;
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .bk-to-h {
            color: #000000;
            font-family: "MuliRegular";
            font-size: 16px;
            text-decoration: underline;
        }
    }


    @media (max-width: 767px) {

        padding: 70px 5px;

        h1{
            font-size: 32px;
        }
        .form-box{
            padding: 0;
            border: none;
        }
    }
}
