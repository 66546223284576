.notification-container {


    .header-wrap{
        background-color: $white;
    }

    .page-inner-header{
        background-color: $white;
    }

    .notification-list {
        padding: 15px;
    }

    .list-card {
        color: $gray-900;
        font-size: 14px;
        font-family: $font-regular;
        position: relative;
        padding-left: 52px;

        i {
            position: absolute;
            left: 15px;
            font-size: 20px;
            top: 15px;
            color: #333;
        }

        span.highlighted-text {
            color: $primary;
        }
        .notify-bal-wrapper{
            .icon-Rits{
                position: relative;
                font-family: $font-regular;
                &:before{
                    margin-right: 3px;
                }
                .credit-debit-warpper{
                    &.c{
                        &:after{
                            content: "C";
                            color:#7E7E7E;
                            font-size: 12px;
                            font-family: $font-bold;
                            margin-left: 2px;
                        }
                    }
                    &.d{
                        &:after{
                            content: "D";
                            color:#7E7E7E;
                            font-size: 12px;
                            font-family: $font-bold;
                            margin-left: 2px;
                        }
                    }
                }
            }
        }

    }

    .shimmer-list-container {

        .shimmer-container {
            background-color: white;
            border-radius: 14px;
            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
            padding: 12px 15px;
            margin-bottom: 10px;

            .image {
                display: inline-block;
                width: 50px;
                height: 50px;
                vertical-align: middle;
            }

            .shimmer-list {
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                margin-left: 12px;
                margin-top: 5px;
                width: 80%;

                @media screen and(max-width: 420px) {
                    width: 76%;
                }

                @media screen and(max-width: 320px) {
                    width: 67%;
                }
            }
        }
    }

    .notification-timing {
        margin: 0;
        font-size: 12px;
        font-family: $font-italic;
        color: $txt-secondary;
    }
}

.nodata-notification-image {
    width: 200px;
    margin-top: 10%;
}

.notification-header {
    padding: 8px 15px;
    z-index: 1005;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 768px;
    background-color: #d7d7d7;
    height: 56px;
    .nt-row {
        display: flex;
        align-items: center;
        height: 100%;
        .back-ic {
            font-size: 16px;
            cursor: pointer;
            padding-right: 15px;
            color: $refsocial-secondary-clr;
        }
        .title-lb {
            flex: 1;
            color: $refsocial-secondary-clr;
            font-family: "MuliBlack";
            font-size: 20px;
            font-weight: 800;
            letter-spacing: 0.5px;
            line-height: 25px;
        }

        .search-area {
            position: relative;
            i {
                color: $refsocial-secondary-clr;
                font-size: 17px;
                cursor: pointer;
            }

            .search-box {
                position: absolute;
                right: 0;
                top: 38px;
                width: 200px;
                transition:  all ease 01s;
                input {
                    height: 40px;
                    border: none;
                    border-radius: 40px !important;
                    width: 100%;
                    padding: 5px 15px;
                }
            }
        }

        .noti-opts {
            .act-dropdown {
                button {
                    background: transparent;
                    background-color: transparent;

                    i {
                        color: $refsocial-secondary-clr;
                        font-size: 17px;
                    }
                    &:focus,
                    &:active {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}


.notification-container{
    .header-wrap{
        display:  none;
    }
}