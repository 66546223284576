.login-modal {
    .modal-header {
        .close {
            background: transparent;
            border: none;
        }
    }

    .eye-btn {
        opacity: 0.8;
    }
}

.modal,
.modal-backdrop {
    // max-width: 768px !important;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    width: 100%;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: $modal-inner-padding 35px;
}

body.modal-open {
    .center-modal {
        overflow: hidden;

        .icc {
            padding: 10px;
            font-size: 14px;
            position: absolute;
            top: 4px;
            right: 4px;
            color: #9e9e9e;
            cursor: pointer;
        }
    }
}

.tooltip-info {
    top: -2px;
}

.modal-content {
    border-radius: $modal-content-border-radius;
}
.center-modal {
    .modal-dialog {
        display: inline-block;
        vertical-align: middle;
        margin: 0 auto;

        .modal-content {
            box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
        }
    }

    text-align: center;

    &:before {
        content: "";
        display: inline-block;
        height: 95%;
        vertical-align: middle;
        margin-right: 0;
        max-height: 100vh;
    }
}

.custom-modal-header {
    padding: 0.875rem 1.2rem;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);

    > .title {
        display: block;
        overflow: hidden;
        font-family: $font-black;
        text-transform: uppercase;
    }

    > .header-link {
        float: right;
        font: 0.75rem $font-bold;
        font-size: $font-size-base;
        margin: 2px 0;

        .link {
            text-transform: uppercase;
            color: $link-color;
            font: 0.7rem $font-bold;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.custom-modal-footer {
    background: linear-gradient(90deg, $cyan 0%, $blue 60%, $blue 90%);
    border-bottom-left-radius: $modal-border-radius;
    border-bottom-right-radius: $modal-border-radius;
    padding: 0;
    border: none;

    .btn {
        width: 100%;
        border: none;
        // @include button-size(0.75rem, $btn-padding-x, 0.875rem, $btn-line-height, $btn-border-radius);
        border-bottom-left-radius: $modal-border-radius;
        border-bottom-right-radius: $modal-border-radius;
        text-transform: uppercase;
        color: $white;
        font-family: $font-bold;
        box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, 0.2);
    }
}

.joined-modal {
    .modal-body {
        padding: 40px;
        text-align: center;

        .thumbs-up-img {
            width: 100px;
        }
    }

    img {
        margin: 0 auto;
    }

    .name {
        margin: 25px 0;
        color: $gray-900;
        font-size: 20px;
        text-transform: uppercase;
        font-family: $font-black;
    }

    .team-img {
        max-width: 43px;
        max-height: 43px;
    }

    .joined-txt {
        margin: 8px 0 30px;
        color: $gray-550;
        font-size: 14px;
        font-family: $font-regular;
    }
}

.verify-modal {
    .modal-header {
        border-bottom: 0;
        padding: 40px 0 30px;
        text-align: center;
        font-size: 20px;
        color: $gray-900;
        font-family: $font-black;
        text-transform: uppercase;
    }

    .modal-body {
        text-align: center;
        padding: 1rem 20px;
    }

    .btn-rounded {
        margin: 50px auto 30px;
        border-radius: 22px;
        font-size: 14px;
        padding: 15px;
        width: 220px;
    }
}

.modal-sm {
    width: 340px;
    max-width: 100vw;
    margin: 30px auto;

    @media (max-width: 340px) {
        width: 100%;
        margin: 30px auto;
    }
}

.have-league-code-modal {
    h2 {
        font-size: 20px;
        font-family: $font-black;
        color: $gray-900;
        text-transform: uppercase;
        margin-bottom: 16px;
        text-align: center;
    }

    p {
        margin: 0 0 35px 0;
        color: $gray-550;
        font-size: 12px;
        text-align: center;
    }

    .or-separat {
        position: relative;
        text-align: center;
        margin: 20px;

        span {
            display: inline-block;
            font-size: 14px;
            color: $gray-200;
            font-family: $font-bold;
            text-transform: uppercase;
            background: $white;
            padding: 2px 8px;
            position: relative;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: $gray-200;
            height: 1px;
            width: 100%;
        }
    }

    .copy-league-codlabel {
        font-size: 12px;
        font-family: $font-bold;
        color: $gray-900;
        text-transform: capitalize;
        margin-bottom: 5px;
        text-align: center;
    }

    .league-code {
        font-size: 20px;
        color: $secondary;
        font-family: $font-bold;
        text-align: center;
    }

    .action-span {
        border-radius: 5px;
        border: 1px solid $gray-225;
        text-align: center;
        font-size: 14px;
        color: $secondary;
        display: inline-block;
        line-height: 35px;
        width: 37px;
        height: 37px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }

    form {
        display: inline-block;
        width: 66%;
    }

    .form-control {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        padding: 7px 13px;
        height: auto;
    }

    .league-link-section {
        .action-span {
            margin-left: 6px;
        }
    }

    .modal-body {
        padding: 1rem 20px 30px 1rem;
    }
}

.custom-checkbox-wrap {
    margin-bottom: 0;
}

.select-more-modal {
    .modal-header {
        padding: 15px;
        text-align: center;
        border-bottom: none;
        text-transform: uppercase;

        .modal-title {
            padding: 0;
            text-align: center;
            font-size: 14px;
            font-family: $font-black;
            color: $secondary;

            a.custom-close-btn {
                position: absolute;
                right: 0;
                display: inline-block;
                float: right;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background: $gray-225;
                color: $gray-900;
                font-size: 8px;
                padding: 3px 0;
                line-height: 14px;
                text-align: center;
            }
        }
    }

    &.modal {
        width: 100vw;
        border-radius: 0;
        height: 100vh;
        max-width: 768px;

        .modal-dialog {
            width: 100vw;
            border-radius: 0;
            min-height: 100vh;
            max-width: 768px;
            margin: 0;

            @media (min-width: 576px) {
                max-width: 768px;
                margin: 0;
            }

            .modal-content {
                width: 100vw;
                border-radius: 0;
                min-height: 100vh;
                max-width: 768px;
            }
        }
    }

    .button-section {
        text-align: center;
        margin-top: 50px;
        text-align: center;

        .btn-rounded {
            border-radius: 22px;
            padding: 0 !important;
            width: 198px;
            height: 44px;
            margin-bottom: 30px;
            font-size: 14px;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        }

        .btn-link {
            text-decoration: underline;
            color: $secondary;
            font-size: 14px;
            font-family: $font-bold;
            text-transform: uppercase;

            &:hover {
                color: $secondary !important;
                opacity: 0.7;
            }

            &.disabled {
                color: lightgray;
            }
        }
    }

    .secondary__value-container {
        padding: 2px 0 !important;
    }

    .secondary__single-value {
        width: 100% !important;
    }

    .modal-body {
        padding-top: 40px;
    }
}

.custom-modal-with-bg {
    margin: 0 auto;
    min-height: 100vh;
    width: 100vw;
    max-width: 768px;

    @media (max-width: 767px) {
        .modal-body {
            min-height: calc(100vh - 300px);
        }
    }

    &.custom-otp-modal {
        @media (max-width: 428px) {
            .modal-body {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                min-height: auto;
                width: 100%;
            }
        }
    }

    &.profile-view-modal {
        @keyframes modalFade {
            from {
                transform: translateY(0);
                opacity: 0;
            }

            to {
                transform: translateY(0);
                opacity: 1;
            }
        }

        .modal-content {
            animation-name: modalFade;
            animation-duration: 0.4s;
            background: #222;
            height: 100%;
            min-height: 100vh;
            width: 100%;

            .modal-body {
                padding: 0;
            }

            .modal-close-btn {
                display: inline-block;
                background: #fff;
                color: #023320;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                position: absolute;
                right: 20px;
                top: 15px;
                font-size: 9px;
                line-height: 19px;
                text-align: center;
            }

            img {
                width: 100%;
                height: 100%;
                min-height: calc(100vh - 50px);
                margin-top: 50px;
                object-fit: scale-down;
                max-height: calc(100vh - 50px);
            }
        }
    }

    .modal-content {
        background: url("../img/hero-banner.png") no-repeat;
        background-position: 80% 10%;
        background-size: cover;
        height: 100%;
        min-height: 100vh;
        width: 100%;
        position: relative;
        border-radius: 0;
        border: none;
        .modal-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.54);
        }
    }

    form.form-section {
        margin-top: -110px;

        @media (max-width: 767px) {
            margin-top: -50px;
        }

        position: relative;
        z-index: 5;

        .btn.btn-rounded {
            min-width: 200px;
            margin-top: 50px;
            text-align: center;
            padding: 13px 25px;
            border-radius: 22px;
            font-size: 14px;
            position: relative;
            z-index: 2;

            @media (max-width: 428px) {
                margin-top: 30px;
            }

            &.signup-btn {
                margin-top: 40px;
            }
        }

        &.otp-form-section {
            margin-top: 0;

            @media (max-width: 320px) {
                margin-top: -30px;
            }
        }
    }

    .forgot-pwd-link {
        display: inline-block;
        color: #ff005a;
        font-size: 14px;
        margin-top: 10px;
        font-family: $font-bold;
        &:hover {
            color: #ff005a !important;
        }
    }

    .double-input-container {
        .input-label-center:not(.label-left) {
            input {
                padding: 16px 15px 10px !important;
            }
        }

        .dual-input-separater {
            position: absolute;
            width: 1px;
            right: 0;
            top: 0;
            height: 42px;
            background: rgba(153, 153, 153, 0.5);
        }
    }

    .select-gender {
        .gender-border {
            border-bottom: 1px solid rgba(153, 153, 153, 0.5);
            margin-top: -3px;
        }

        .secondary__placeholder,
        .secondary__single-value {
            left: 0;
            right: 0;
            font-size: 14px;
            font-family: $font-bold;
            color: $white;
        }

        .select-arr {
            width: 20px;
            right: -7px;
        }
    }

    .datepicker_display {
        font-family: $font-bold;
        color: $white;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        position: relative;
        font-size: 14px;
        margin-top: -12px;
        z-index: 6;

        .dobField {
            position: relative;

            .dob-text {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                cursor: pointer;
                padding: 12px;
            }
        }

        .react-date-picker__calendar-button.react-date-picker__button {
            color: $white;
            margin-right: -8px;
        }

        .react-datepicker-wrapper {
            display: inline;

            .react-datepicker__input-container {
                display: inline;

                input {
                    border-color: #ffffff;
                    border: 0px solid white;
                    width: 100%;
                    border-width: 0px;
                    color: #333333;

                    &:disabled {
                        background-color: white;
                    }
                }
            }
        }

        .react-date-picker__inputGroup {
            opacity: 0;
        }

        .react-date-picker--disabled {
            background-color: transparent;
        }
    }

    &.confirmation-modal {
        form.form-section {
            margin-top: 0;
            padding: 0 25px 25px;
        }
    }

    // input:-webkit-autofill,
    // input:-webkit-autofill:hover,
    // input:-webkit-autofill:focus,
    // input:-webkit-autofill:active {
    //     transition: background-color 5000s ease-in-out 0s;
    //     -webkit-text-fill-color: #fff !important;
    // }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition-delay: 9999s;
        transition-delay: 9999s;
    }

    input:focus,
    input:hover {
        border-color: rgba(153, 153, 153, 1) !important;
    }
}

.custom-checkbox {
    input[type="checkbox"],
    input[type="radio"] {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it

        & ~ span {
            position: relative;
            cursor: pointer;
            padding: 0;
            display: block;
            width: 100%;
            padding: 3px;
            padding-left: 30px;
            max-width: 100%;
            overflow: hidden;
            color: $white;
            font-size: 12px;

            a {
                display: inline-block;
                // color: inherit !important;
                margin-left: 5px;
            }
        }

        // Box.
        & ~ span:before {
            content: "";
            margin-left: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: transparent;
            border-radius: 50px;
            border: 1px solid #d1d1d1;
            margin: 0;
            position: absolute;
            left: 0;
        }

        // Box checked
        &:checked ~ span {
            &:before {
                background: $primary;
                border: none;
            }
        }

        // Disabled state label.
        &:disabled ~ span {
            color: #b8b8b8;
            cursor: auto;
        }

        // Disabled box.
        &:disabled ~ span:before {
            box-shadow: none;
            background: #ddd;
        }

        // Checkmark. Could be replaced with an image
        &:checked ~ span:after {
            content: "";
            position: absolute;
            top: 12px;
            left: 5px;
            background: $black;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 $black, 4px 0 0 $black, 4px -2px 0 $black, 4px -4px 0 $black, 4px -6px 0 $black,
                4px -8px 0 $black;
            transform: rotate(45deg);
        }
    }
}

.dob-date-picker {
    z-index: 1001;

    .datepicker_display > label {
        display: inline-block;
        font-family: $font-regular;
        padding-top: 12px;
        position: relative;
        padding-bottom: 0px;
        margin-bottom: 0px;
        width: 100%;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
    }
}

.date-picker-custom {
    width: 100%;
    padding: 5px 10px 5px;
    border-bottom: 1px solid rgba(153, 153, 153, 0.5);

    &.edit-profile {
        padding: 0px 2px 0px;
        color: #b4b4b4;
    }

    .react-date-picker__wrapper {
        width: 100%;
        border: none;
    }

    .react-date-picker__clear-button {
        display: none;
    }

    &.react-date-picker--disabled {
        background-color: $input-disabled-bg;
    }

    .react-date-picker__inputGroup__year {
        width: 100% !important;
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__next-button {
        font-size: 21px;
    }

    .react-calendar__navigation__label {
        line-height: 3.2;
    }
}

.modal-header-bg {
    padding: 70px 20px;
    text-align: center;
    background: url("../img/whistle-shape-bg.png") no-repeat;
    background-position: bottom center;
    background-size: cover;
    height: 400px;

    @media (max-width: 428px) {
        height: 300px;
    }

    z-index: 2;
    position: relative;

    img {
        max-width: 85px;
    }

    .modal-title {
        font-size: 16px;
        font-family: $font-bold-italic;
        color: $refsocial-secondary-clr;
    }

    .modal-close-btn {
        display: inline-block;
        background: $refsocial-secondary-clr;
        color: $white;
        padding: 2px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 25px;
        top: 15px;
        font-size: 10px;

        @media (max-width: 767px) {
            right: 15px;
        }
    }

    &.modal-header-without-bg {
        background: transparent;
        height: 180px;

        .otp-heading {
            color: $white;
            font-size: 16;
            font-family: $font-bold;
            text-align: center;
        }
    }
}

.otp-heading {
    color: $white;
    font-size: 16;
    font-family: $font-bold;
    text-align: center;
    margin-bottom: 50px;
}

.user-help {
    color: $white;
    font-size: 14px;
    font-family: $font-bold;
    padding-top: 30px;

    a {
        color: $primary !important;
    }
}

.confirmation-modal-header {
    position: relative;
    z-index: 1;

    .skip-btn {
        display: inline-block;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
        font-family: $font-bold;

        &:hover {
            color: #999 !important;
        }
    }

    .modal-close-btn {
        display: inline-block;
        background: $white;
        color: $secondary;
        padding: 2px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 15px;
        top: 20px;
        text-align: center;
        font-size: 10px;
    }

    .modal-title {
        font-size: 16px;
        font-family: $font-bold;
        text-transform: capitalize;
        color: $white;
        margin: 10px 0 35px;
        text-align: center;
    }

    .user-profile-pic {
        figure {
            width: 132px;
            height: 132px;
            border-radius: 50%;
            border: 2px solid $white;
            margin: 0 auto;
            overflow: hidden;
            background: $white;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .upload-banner-section {
        background: rgba(100, 100, 100, 0.2);
        color: $white;
        padding: 20px;
        margin: 20px 0;
        text-align: center;
        position: relative;
        height: 125px;

        h2 {
            font-size: 16px;
            text-transform: capitalize;
            font-family: $font-bold;
        }

        p {
            font-size: 12px;
            font-family: $font-italic;
            margin: 5px 0;
            color: rgba(255, 255, 255, 0.8);

            &.optional {
                position: absolute;
                right: 20px;
                top: 5px;

                @media (max-width: 767px) {
                    right: 10px;
                }
            }
        }

        .btn-rounded {
            width: 120px;
            height: 30px;
            margin-top: 5px;
        }

        i.icon-cross-circular {
            position: absolute;
            right: 20px;
            top: 15px;
            color: $white;
            font-size: 16px;
            cursor: pointer;
        }
    }

    &.complete-profile-modal {
        .modal-title {
            margin: 0 0 15px;
            padding: 0px 10px 20px 10px;
        }
    }
}

.otp-field {
    > div {
        justify-content: center;

        > div:not(:last-child) {
            margin-right: 10px;
        }
    }

    label {
        font-size: 12px;
        text-align: center;
        display: block;
        color: $white;
        font-family: $font-regular;
    }

    input {
        text-align: center;
        background: transparent;
        border: 0;
        width: 40px !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        color: $white;
        font-family: $font-bold;
        font-size: 26px;
        padding: 10px 0;
    }
}

.txt-verify-no {
    color: $white;
    margin: 30px auto;
    text-align: center;
    font-size: 12px;
    font-family: $font-regular;

    a {
        display: inline-block;
        margin-left: 5px;
        color: $white;

        &:hover,
        &:focus,
        &:active,
        &.active {
            color: $primary !important;
        }
    }
}

.timer-resend {
    color: $red;
    font-size: 12px;
    text-align: center;
    margin-top: 30px;
    display: inline-block;
}

.link-txt {
    color: $primary;
    display: block;
    font-family: $font-bold;
    margin-top: 30px;
}

.hidden-align {
    position: absolute;
    top: 18px;
    right: 5px;

    i {
        color: $white;
        font-size: 20px;

        &.icon-eye-cancel {
            font-size: 24px;
        }

        &.dark {
            color: #333;
            font-size: 23px;
        }
    }
}

.prediction-modal {
    background-color: #f8f9fa;

    .modal-content {
        box-shadow: none;
    }

    .btn-block.bottom {
        bottom: 0;
        position: fixed;
        border-radius: 0;
        font-size: 14px;
    }

    .prediction-quiz {
        .quiz-section {
            border-radius: 7px;
            margin-bottom: 10px;

            li {
                padding: 13px 10px;

                .que-name {
                    color: $gray-675;
                    font-size: 12px;
                    font-family: $font-bold;
                }

                .quiz-query {
                    font-size: 14px;
                    font-family: $font-bold;
                    color: $gray-900;

                    &.preview-final {
                        display: flex;
                        flex-direction: row;
                    }
                }

                &.team-score-list {
                    width: 90%;
                    text-align: left;
                    font-size: 18px;

                    @media (max-width: 428px) {
                        width: 83%;
                    }
                }
            }
        }
    }
}

.membership-modal {
    .modal-body {
        padding: 1rem 15px;
        padding-top: 0;
    }

    .premium-block {
        padding-right: 7px;
    }

    .premium-plus-block {
        padding-left: 7px;
    }

    .modal-header {
        border-bottom: 0;
        padding: 0;
        text-align: center;

        h4 {
            color: #030f09;
            font-size: 16px;
            font-family: $font-bold;
            max-width: 230px;
            margin: 0 auto;
        }
    }

    .plan-heading {
        color: $gray-675;
        font-size: 14px;
        font-family: $font-bold;
        text-align: center;
        margin-bottom: 10px;
    }

    .buy-plan-btn .btn {
        padding: 0 45px;
        height: 44px;
        border-radius: 22px;
        width: auto;
        font-size: 14px;
    }
}

.green-text {
    color: #023320;
    font-size: 13px;
    font-family: "MuliItalic";
}

.link-anchor {
    color: $secondary;
    font-size: 13px;
    font-family: $font-italic;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 5px;
    display: block;
    margin-top: 10px;
}

.buy-plan-btn {
    .btn {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        height: 30px;
        width: 110px;
        margin: 8px;
    }
}

.plan-block {
    border: 1px solid $border-light;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    color: $gray-910;
    font-size: 10px;
    font-family: $font-italic;
    text-align: center;
    height: 240px;
    position: relative;
    margin-bottom: 20px;

    p {
        margin-bottom: 10px;
    }

    .block-header {
        padding: 10px;
        background: url("../img/membership-bg.png");
        height: 88px;
        background-size: cover;
        background-position: bottom center;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .amt {
        font-size: 30px;
        font-family: $font-bold-italic;
        color: $gray-800;
        line-height: 1.1;

        span {
            position: relative;
            display: inline-block;
        }

        i {
            position: absolute;
            left: -16px;
            top: 0px;
            font-size: 16px;
            color: $primary;
        }
    }

    .plan-duration {
        font-size: 10px;
        color: $gray-575;
    }

    .block-body {
        padding: 6px;
    }
}

.joined-modal.payment-confirm-modal {
    .modal-body {
        padding: 30px;
    }

    .name {
        margin-bottom: 5px;
    }

    .joined-txt {
        color: $gray-910;
    }

    .amt {
        font-size: 40px;
        font-family: $font-bold-italic;
        color: $gray-800;
        line-height: 1.1;

        span {
            position: relative;
            display: inline-block;
        }

        i {
            position: absolute;
            left: -16px;
            top: 0px;
            font-size: 16px;
            color: $primary;
        }
    }

    .subscription-text {
        border-bottom: 1px solid $border-light;
        margin-bottom: 40px;
        padding-bottom: 8px;
    }

    .subscription-for {
        font-size: 14px;
        font-family: $font-bold-italic;
        color: $gray-900;
    }

    .btn {
        padding: 15px 40px !important;
    }
}

.congrats-popup {
    height: 85vh;
    align-items: center;
    display: flex;
    margin: auto;
    padding: 13px;

    .btn {
        height: 44px;
        width: 218px;
        border-radius: 22px;
        background: linear-gradient(73.19deg, #00af8b 0%, #00ff87 100%);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        font-size: 14px;
    }
}

.user-action-modal {
    .modal-body {
        padding: 40px 0 0 0;
    }

    .user-action-body {
        text-align: center;
        padding: 0;

        h4 {
            font-size: 20px;
            font-family: $font-black;
            text-transform: uppercase;
            color: $gray-900;
            margin: 20px 0;
            text-align: center;
        }

        ul {
            margin: 20px 0;
            padding: 0;

            li {
                padding: 15px;
                border-bottom: 1px solid $border-light;
                display: table;
                width: 100%;

                div {
                    text-align: left;
                    display: table-cell;
                    width: 50%;
                    font-size: 14px;
                    color: $gray-550;
                    font-family: $font-regular;

                    &:last-child {
                        text-align: right;
                        color: $gray-900;
                        font-family: $font-bold;
                        max-width: 100px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .btn {
            margin: 10px 0 40px;
            padding: 14px 30px;
            border-radius: 30px;
            font-size: 14px;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        }
    }

    .modal-msg {
        color: $gray-910;
        font-size: 14px;
        max-width: 280px;
        margin: 0 auto 20px;
    }

    .footer-action {
        display: flex;
        justify-content: space-evenly;
    }
}

input {
    border-radius: 0 !important;
}

.bottom-fixed {
    @media (max-width: 428px) {
        position: absolute;
        bottom: 20px;
        width: 100%;
        left: 0;
    }

    @media (max-width: 415px) {
        position: static;
    }
}

.alert-modal {
    text-align: center;

    h2 {
        font-family: $font-black;
        text-transform: uppercase;
        font-size: 22px;
        margin: 20px 0;
    }

    .modal-body {
        padding: 0;
    }

    .modal-footer {
        padding: 0;
        border: 0;
        background: linear-gradient(75.01deg, #00af8b 0%, $primary 100%);
        border-radius: 0 0 16.5px 16.5px;
        margin-top: 40px;

        a {
            width: 50%;
            display: inline-block;
            padding: 10px;
            border-radius: 0;
            text-align: center;
            color: $white;
            font-size: 14px;
            font-family: $font-black;

            &:first-child {
                border-right: 1px solid $white;
            }
        }
    }
}
.alert-modal .modal-footer {
    flex-wrap: nowrap;

    a {
        &:hover {
            color: #fff !important;
        }
    }
}

.cal-ic-sgn {
    position: absolute;
    right: 0;
}

.secret-access-modal {
    // max-width: 400px;
    .modal-content {
        border-radius: 10px;
    }

    .modal-body {
        padding: 0;
        border-radius: 10px;
        overflow: hidden;
    }

    .heading {
        text-align: left;
        background-color: #03f5e7;
        padding: 15px;
        color: #37003c;
        font-size: 16px;
        font-weight: 800;
    }

    .f-body {
        padding: 60px 30px;
        text-align: center;
        .form-group {
            margin-bottom: 30px;
            label {
                font-size: 12px;
                font-weight: bold;
                width: 100%;
                letter-spacing: 1px;
            }

            input {
                width: 100%;
                border: none;
                border-bottom: solid 1px #000;
                margin-bottom: 5px;
                text-align: center;
                padding: 10px;

                &.error {
                    border-bottom-color: red;
                }
            }

            .note {
                font-size: 10px;
                letter-spacing: 0;
            }
        }

        .submit-btn {
            margin-top: 50px;
            height: 44px;
            width: 198px;
            border-radius: 30px;
            font-size: 14px;
        }
    }
}

.coming-soon-modal {
    width: 350px;
    max-width: 100%;

    .modal-content {
        border-radius: 40px;
    }
    .hd-img {
        width: 85px;
        height: 85px;
        background-color: #fff;
        border-radius: 50%;
        margin: 0 auto;
        margin-top: -40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        span {
            height: 60px;
            width: 60px;
            background-color: #f4f4f4;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            img {
                width: 40px;
            }
        }
    }

    .cm-body {
        padding: 0 30px;

        h2 {
            color: #212121;
            font-family: "CocoSharp-Extrabold";
            font-size: 24px;
            margin-bottom: 15px;
        }

        h4 {
            color: $purple;
            font-family: "CocoSharp-Bold";
            font-size: 20px;
            margin-bottom: 25px;
        }

        .t-content {
            color: #6d7278;
            font-family: "cocosharp-regular";
            font-size: 16px;
            margin-bottom: 20px;
        }

        .social-ics {
            display: flex;
            list-style-type: none;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            li {
                a {
                    color: #6d7278;
                    i {
                        font-size: 20px;
                    }
                    img {
                        width: 25px;
                    }

                    &:hover {
                        i,
                        img {
                            color: $primary;
                        }
                    }
                }
            }
        }

        .cm-foot {
            padding: 15px 0;
            padding-bottom: 30px;

            button.close-btn {
                height: 44px;
                width: 220px;
                max-width: 100%;
                color: $purple;
                font-size: 16px;
                border-radius: 30px;
            }
        }
    }
}


.terms-condi-check span a.clr-1{
    color: $primary  !important;
}