.custom-acc-fq {
    // border: solid 1px #d2d2d2;
    margin-bottom: 15px;
    
    .cs-head {
        padding: 15px 0;
        cursor: pointer;
        user-select: none;
        position: relative;
        border-bottom: solid 1px #c6c6c6;
        font-family: "CocoSharp-Bold";
        h4 {
            margin: 0;
            padding-right: 30px;
            font-size: 16px;
            font-weight: 700;
        }

        &:after{
            font: normal normal normal 22px/1 FontAwesome;
            content: "\f107";
            position: absolute;
            right: 0px;
            top: 13px;
            transition: all ease 0.5s;
            
        }
    }

    .card {
        padding: 15px;
    }

    &.active{
        .cs-head {
            border-bottom: solid 1px #d2d2d2;
            &:after{
                transform: rotate(180deg);
            }
        }

    }

    .bdy{
        font-family: "CocoSharp-Light";
    }
}
