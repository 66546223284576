.app-header {
    background-color: $primary;
    height: 67px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 30px;
    z-index: 100;
    .row {
        align-items: center;
        height: 100%;
    }

    .nav-col {
        display: none;

        i {
            font-size: 20px;
            cursor: pointer;
        }
    }

    .navigation-col {
    }

    .logo-p {
        text-align: center;

        img {
            height: 30px;
        }
    }

    .mid-prt {
        // overflow-x: auto;

        .hd-part {
            display: none;
        }
        ul {
            display: flex;
            align-items: center;
            list-style-type: none;
            justify-content: space-between;
            margin-bottom: 0;
            padding-left: 0;

            li {
                font-weight: 600;
                padding-right: 15px;
                cursor: pointer;
                color: $purple;
                font-family: "CocoSharp-Bold";
            }
        }

        .user-drop {
            user-select: none;
        }
    }

    .r-p {
        text-align: center;
        span {
            border-left: solid 1px $black;
            padding-left: 30px;
            display: block;
            height: 100%;
            max-width: 50px;
            margin: 0 auto;
            cursor: pointer;
            img {
                height: 25px;
            }
        }

        .user-drop {
            .btn-secondary {
                background: transparent;
                border: none;
                outline: none;
                box-shadow: none;
                padding: 0;
                padding-right: 5px;
                &:active {
                    outline: none;
                    box-shadow: none;
                }
            }

            .drop-thumb {
                display: inline-block;
                // overflow: hidden;

                img {
                    width: 25px;
                    height: 25px;
                    border-radius: 7px;
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        padding: 15px;

        .nav-col {
            display: block;
        }
        .navigation-col {
            position: fixed;
            width: 100%;
            background: $primary;
            height: 100%;
            top: 0;
            left: -100%;
            transition: all ease 0.5s;
            padding: 0;
            overflow-y: auto;
            z-index: 100;
            &.show {
                left: 0;
            }
            .mid-prt {
                .hd-part {
                    display: block;
                    text-align: center;
                    border-bottom: solid 1px $black;
                    padding: 10px;

                    span {
                        padding: 0 30px;
                    }
                    img {
                        width: 40px;
                    }

                    i {
                        position: absolute;
                        left: 10px;
                        top: 15px;
                        cursor: pointer;
                    }
                }

                ul {
                    display: block;
                    padding: 10px 30px;
                    li {
                        padding: 15px 0;
                        border-bottom: solid 1px $black;
                    }
                }

                .user-drop .dropdown-menu {
                    position: relative !important;
                    background: transparent;
                    border: none;
                    padding-top: 0;
                    transform: none !important;
                }
            }
        }

        .r-p {
            text-align: right;

            span {
                max-width: 100%;
                border-left: 0;
                display: inline-block;
            }
        }
    }
}


.floating-nav{
    background: $primary;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    border-top: solid 1px #000;
    box-shadow: 0 -1px 10px 0 rgba(0,0,0,0.1);
    padding: 5px 0;

    display: none;
    ul{
        display: flex;
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        li{
            flex: 1;
            text-align: center; 
            position: relative;
            &:before{
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                width: 1px;
                height: 100%;
                background-color: #000;
            }

            &:last-child:before{
                display: none;
            }
            span{
                display: block;
                padding: 10px;
                text-transform: uppercase;
                font-family:  "CocoSharp-Bold";
                font-size: 12px;
                cursor: pointer;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        display: block;
    }
}