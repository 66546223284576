html,
body {
    height: 100%;
}

body {
    background: $gray-170;
    background: url("../img/hero-banner.png");
    background-size: cover;
    background-attachment: fixed;
}

.toast-notification {
    margin: 0 auto !important;
    transform: translate(0) !important;
    width: 100% !important;
    max-width: 768px !important;
    z-index: 9999;

    @media (max-width: 767px) {
        width: 100vw !important;

        span {
            width: 100vw !important;
            border-radius: 0 !important;
        }
    }
}

.lg-sec {
    display: none;
    &.active {
        display: block;
    }
}

.form-group {
    margin-bottom: 25px;
}
.lg-wd-ref {
    text-align: center;
    padding-top: 30px;

    .hdng_1 {
        color: #fff;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 30px;

        .tx {
            position: relative;
            background: #00000091;
            padding: 7px 30px;
            border-radius: 30px;
            font-size: 12px;
            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 65px;
                height: 1px;
                background: #fff;
                margin-top: 10px;
            }

            &:before {
                left: -65px;
            }
            &:after {
                right: -65px;
            }
        }

        @media (max-width: 540px) {
            .tx {
                &:before,
                &:after {
                    width: 50px;
                }
                &:before {
                    left: -65px;
                }
                &:after {
                    right: -65px;
                }
            }
        }
    }
}
.btn.ref-id-btn {
    margin: 0 auto;
    min-height: 44px;
    min-width: 198px;
    border-radius: 22px;
    background-color: $primary;
    background: $primary;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    color: $refsocial-secondary-clr !important;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 32px;
        margin-right: 5px;
    }

    &:hover,
    &:focus {
        color: #000 !important;
    }
}

.modal-title {
    padding: 15px 10px;

    .vissel-img {
        width: 45px;
    }

    position: relative;
}

.verification-block {
    min-height: 97px;
    position: relative;
}

.modal-txt {
    font-size: 13px;
    color: $white;
    text-align: center;
    max-width: 520px;
    margin: 0 auto 20px;
}

.input-label-center {
    position: relative;
}

.input-label-center .secondary__value-container {
    text-align: center !important;
}

.wp-mod-sh {
    text-align: center;
    margin-bottom: 10px;
    .ic-grp {
        background: rgba(0, 0, 0, 0.6);
        display: inline-block;
        padding: 6px 10px;
        border-radius: 30px;
        min-height: 36px;
        span {
            margin: 0 7px;
        }
    }
}

.select-secondary {
    z-index: unset;
}

.rkfloatingLabelInput {
    flex-direction: column;
    box-sizing: border-box;
    display: inline-block;
    font-family: MuliRegular;
    padding-top: 5px;
    position: relative;
    padding-bottom: 0px;
    margin-bottom: 0px;
    width: 100%;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);

    &.editp {
        color: rgb(51, 51, 51);

        input {
            color: rgb(51, 51, 51);
        }

        span {
            &.on {
                color: rgb(158, 158, 158);
            }
        }
    }

    &.withdraw {
        color: rgb(51, 51, 51);

        input {
            color: rgb(51, 51, 51);
            margin-top: 3px;
        }

        span {
            color: rgb(105, 105, 105);

            &.on {
                color: rgb(126, 126, 126);
            }
        }
    }

    span {
        position: absolute;
        top: 0px;
        left: 0;
        font-size: 1rem;
        pointer-events: none;
        padding: 17px 0px 13px;

        transition: font-size 200ms ease 0s, padding 200ms ease 0s;

        &.on {
            padding: 0;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);
        }
    }

    input {
        border-top-width: initial;
        border-right-width: initial;
        border-bottom-width: 1px;
        border-left-width: initial;
        border-style: none none solid;
        border-image: initial;
        box-sizing: border-box;
        font-family: MuliBold;
        font-size: 16px;
        padding: 16px 0px 10px;
        width: 100%;
        color: rgb(255, 255, 255);
        background-color: transparent;
    }
}

.ref_id_grp {
    position: relative;

    input {
        padding-left: 40px !important;
        padding-right: 40px !important;
        // text-align: left;
    }
    .r-con {
        position: absolute;
        left: 0;
        color: $primary;
        width: 40px !important;
        font-size: 20px;
        bottom: 8px;
        font-weight: 900;
        border-radius: 5px;
        font-family: "Muli-ExtraBold";
    }
}

.input-label-center.has-error input {
    border-color: #ff4e5d !important;
}

.phone-grp-reg {
    .phone_code_sel {
        margin-top: 10px;
        position: absolute;
        background: rgba(256, 256, 256, 0.15);
        width: 70px;
        border-radius: 30px;
        > div {
            padding-bottom: 0;
            margin-bottom: 0;
        }
        .secondary__single-value {
            color: #fff;
            font-family: "Muli-ExtraBold";
        }
        .secondary__placeholder {
            font-size: 12px;
        }
        .secondary__control {
            padding-right: 25px;
        }
        .select-arr {
            top: 6px !important;
            right: -13px !important;
        }

        &.has-error {
            border: solid 1px red;
        }
    }

    .phone_num {
        span,
        input {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
}

.custom-checkbox-wrap {
    margin-bottom: 0;
}

.help-text {
    font-size: 11px;
    font-family: $font-italic;
    color: $gray-550;
    position: relative;
    top: -20px;
    text-align: center;
}

.form-group.has-error .select-gender .gender-border,
.form-group.has-error .select-field-default .gender-border {
    border-bottom-color: #ff4e5d;
}

.Site-content {
    background: transparent;
    // max-height: 100vh;
}

.modal-open .modal {
    overflow-x: hidden;
    padding-left: 0 !important;
}

body.modal-open .modal {
    overflow-y: auto;
}

.modal-open .modal.signup-modal {
    overflow-y: auto;
}
.modal-open {
    overflow: hidden;
    padding-right: 0 !important;
}

.btn {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        border-radius: inherit;
        transform: scale(0);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0 auto;
    }

    &:hover,
    &:focus {
        color: #fff !important;
    }

    &:active {
        &:before {
            animation: ripple-in2 2s;
        }
    }
}

@keyframes ripple-in2 {
    0% {
        transform: scaleX(0);
    }

    20% {
        transform: scaleX(1);
        opacity: 0.3;
    }

    100% {
        transform: scaleX(1);
        opacity: 0;
    }
}

.complete-profile-modal {
    .f-gr-area {
        background: $primary;
        padding: 20px 15px;
        text-align: center;
        position: relative;
        .logo {
            height: 40px;
            margin: 0 auto;
            margin-bottom: 15px;
        }

        .modal-close-btn {
            margin-right: 15px;
            margin-top: 15px;
        }
    }

    .tabie-ar {
        display: flex;
        align-items: center;
        position: relative;
        max-width: 370px;
        z-index: 0;
        margin: 0 auto;
        margin-top: 10px;
        &:before {
            background-color: #fff;
            height: 1px;
            position: absolute;
            content: "";
            left: 50px;
            width: calc(100% - 100px);
            top: 30px;
            z-index: -1;
        }
        .tbie {
            text-align: center;
            flex: 1;
            cursor: pointer;
            span {
                height: 40px;
                width: 40px;
                background-color: #fff;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 18px;
                }
            }

            div {
                margin-top: 10px;
                font-size: 11px;
                font-weight: bold;
                text-transform: uppercase;
                color: $refsocial-secondary-clr;
            }

            &.completed {
                span {
                    background-color: $refsocial-secondary-clr;
                }
            }

            &.active {
                span {
                    height: 60px;
                    width: 60px;
                    img {
                        width: 32px;
                    }
                }
                div {
                    font-size: 13px;
                    font-weight: 900;
                    font-family: "MuliBold";
                }
            }
        }
    }
}

.user-profile-pic {
    position: relative;

    .select-profile-section {
        background: #fff;
        display: block;
        box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.05);
        width: 38px;
        height: 38px;
        font-size: 20px;
        color: #7e7e7e;
        text-align: center;
        padding: 2px 5px;
        border-radius: 50%;
        position: absolute;
        right: 41%;

        @media (max-width: 428px) {
            right: 32%;
        }

        top: -4px;
    }
}

.cp-tab {
    display: none;
    &.active {
        display: block;
    }
}

.mem-tab-ar {
    background: $primary;
    display: flex;
    padding: 0 2px;
    overflow-x: auto;
    .mem-tab {
        height: 44px;
        border-radius: 6px 6px 0 0;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        flex: 1;
        border: solid 2px $primary;
        color: $refsocial-secondary-clr;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: none;
        cursor: pointer;
        min-width: 120px;
        font-size: 12px;
        &.active {
            // background: #000;
            background-color: $refsocial-secondary-clr;
            color: $white;
        }
    }
}

.cp-profile-modal {
    &.member-tab-active {
        .modal-content .modal-overlay {
            background: #fff;
        }
    }
    .modal-body {
        padding: 0 !important;
    }
}

.mem-tab-body {
    padding: 15px 50px;
    overflow-x: hidden;
    &.mem-slider-body{
        padding: 15px 00px;  
    }
    .membership-slider{
            .slick-prev{
                left: 0;
                z-index: 999;
              
                &::before{
                    //content: "\e9b3";
                    z-index: 999;
                    color: #333333!important;
                    opacity: 0.3;
                }
            }
            .slick-next{
                right: 0;
                &::before{
                    //content: "\e9b3";
                    z-index: 999;
                    color: #333333!important;
                    opacity: 0.3;
                }
            }
        
        .slick-dots{
            bottom: unset;
            top: 0;
            display: flex !important;
            background-color: #4ff5e6;
            padding: 0 10px;
            li{
                width: 33.33%;
                margin: 0 auto;
                height: auto;
                background-color: $white;
                border-radius: 6px 6px 0 0;

                .mem-tab-ar{
                    background: transparent;
                    padding: 0;
                }
                .mem-tab{
                    color: #37003C;
                    font-family: $font-bold;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 18px;
                    text-align: center;
                    background-color: transparent;;
                    border: none;
                    
                }
                &.slick-active{
                    border-radius: 6px 6px 0 0;
                    background-color: #37003C;
                    .mem-tab{
                        color:$white;
                        border: none;
                    }
                }
            }
        }
    }
    .mem-tab-dv {
        display: none;
        // padding: 30px 15px;
        // border-radius: 10.89px;
        // background-color: #ffffff;
        // box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
        // padding-top: 50px;
        min-height: 300px;
        margin-top: 60px;
        position: relative;
        &.active {
            display: block;
        }

        &:before,
        &:after {
            content: "";
            height: 80%;
            width: 60px;
            border-radius: 10.37px;
            position: absolute;
            background-color: #ffffff;
            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
            top: 10%;
            // display: none;
            @media(max-width:767px){
                width: 30px;
            }
        }

        &:before {
            // left: -80px;
            left: 4px;
            @media(max-width:767px){
                left: 0px;
            }
        }

        &:after {
            // right: -80px;
            right: 4px;
            @media(max-width:767px){
                right: 0px;
            }
        }
.mem-inner-body {
    border-radius: 10.89px;
    background-color: #ffffff;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.1);
    width: 80%;
    margin: 0 auto;
    @media(max-width:767px){
        width: 75%;
    }
}
        .head-part {
            background: url("../img/membership-bg.png") no-repeat;
            background-size: cover;
            background-position: center bottom;
            padding: 20px 20px 70px;
            min-height: 260px;
            padding-top: 50px;
        }
    }
}


.mem-tab-dv,
.mem-tab-dv2 {
    .mm-hdng {
        color: $black;
        font-family: MuliRegular;
        font-size: 18px;
        font-style: italic;
        font-weight: bold;
        letter-spacing: 0;
        text-align: center;
    }

    .fr-h {
        color: $black;
        font-family: MuliRegular;
        font-size: 40px;
        font-style: italic;
        font-weight: bold;
        letter-spacing: 0;
        text-align: center;
    }

    .feat-dv {
        min-height: 250px;
        display: flex;
        align-items: center;

        .feats {
            padding-left: 0;
            padding: 0 10px;
            width: 100%;
            li {
                list-style-type: none;
                text-align: center;
                font-size: 12px;
                padding: 12px 0;
                border-bottom: solid 1px #ededed;
                color: #333333;
                font-family: "MuliItalic";
                img{
                    display: inline-block;
                }
                &:last-child {
                    border-bottom: none;
                }
                &.bold {
                    font-weight: bold;
                    font-family: "MuliBold";
                }

                .sub-feat{
                    color: grey;
                }
            }
        }
    }

    .old-plan {
        text-align: center;
        margin-top: 10px;
        cursor: default;
        opacity: 0.45;
        i {
            color: $primary;
            margin-right: 5px;
            font-style: normal;
            position: absolute;
        }
        span {
            color: #333333;
            font-family: MuliRegular;
            font-size: 20px;
            font-weight: 800;
            letter-spacing: 0;
        }
        strike{
            padding-left: 15px;
        }
    }

    .offered-plan {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 30px;
        i {
            font-size: 20px;
            color: $primary;
            margin-right: 5px;
            position: absolute;
            font-style: normal;
        }
        .vl {
            color: $black;
            font-family: "Muli-ExtraBold";
            font-size: 26px;
            padding-left: 15px;
        }
        .prd {
            color: #999999;
            font-family: MuliRegular;
            font-size: 12px;
        }
    }

    .prem-plan-types {
        display: flex;
        // align-items: center;
        justify-content: center;
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 30px;
        .pr-che {
            text-align: center;
            padding: 5px 20px;
            flex: 1;
            cursor: pointer;
            .lbl {
                display: block;
                color: #999999;
                font-family: "Muli-ExtraBold";
                font-size: 10px;
                text-transform: uppercase;
            }

            .vl {
                font-size: 16px;
                font-family: "Muli-ExtraBold";
                color: $black;
                margin-bottom: 7px;
                display: block;
                i {
                    color: $primary;
                }

                &.old {
                    color: #000000;
                    opacity: 50%;
                    font-size: 10px;
                    line-height: 21px;
                    margin-bottom: 0;
                    margin-top: 5px;

                }
            }

            .checkie {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                width: 24px;
                background-color: #efefef;
                border-radius: 50%;
                img {
                    width: 13.71px;
                    opacity: 0;
                }
            }

            &.active {
                .checkie {
                    background-color: $primary;
                    img {
                        opacity: 1;
                    }
                }
            }

            @media (max-width: 540px) {
                padding: 5px 10px;
            }
        }
    }
}

.offer-m {
    text-transform: none !important;
    color: #000;
    font-family: "MuliRegular";
    font-size: 10px;
}

.mem-tab-dv2 {
    display: none;
    &.active {
        display: block;
    }
}

.pln-bt-ar {
    text-align: center;
    padding: 50px;
    .plan-btn {
        min-height: 44px;
        width: 198px;
        border-radius: 22px;
        background: #40edd0;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
        border: none;
        color: #000;
        font-family: MuliRegular;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        span {
            display: block;
            color: #37003c;
            font-size: 10px;
            font-family: "Muli-ExtraBold";
        }

        .offer-m {
            color: #000;
            font-family: "MuliRegular";
        }
    }
}

.fr-d-fn {
    font-family: "CocoSharp-Bold" !important;
}

.secret-inp {
    input {
        &::placeholder {
            font-size: 12px;
            color: rgba(253, 253, 253, 0.8);
            font-style: italic;
            font-family: "MuliRegular";
        }
    }

    .note {
        text-align: center;
        opacity: 0.8;
        color: #ffffff;
        font-size: 10px;
        letter-spacing: 0;
    }
}

.secret-ques-bl {
    text-align: center;
    color: #ffffff;
    label {
        font-family: "MuliRegular";
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
    }

    .se-val {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.13px;
        line-height: 23px;
    }
}



.cmn-site-container {
    max-width: 1300px;
    margin: 0 auto;
}

.join-fq{
    color: #ff015b;
    cursor: pointer;
    font-weight: 700;
}

.custom-acc-fq {
    .bdy{
        >div{margin-bottom: 10px;}
    }
}