.home-sec {
    background: #fff;
    min-height: 100vh;
    .hm-strip {
        text-align: center;
        background-color: $purple;
        color: #fff;
        padding: 5px 15px;
    }

    .bg-grey {
        background-color: #f7f7f7;
    }

    .hero-sec {
        min-height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 30px 0;
        text-align: center;

        h1 {
            font-family: "CocoSharp-Bold";
            font-weight: 800;
            text-transform: uppercase;
            font-size: 50px;
        }

        button {
            border-radius: 5px;
            background-color: #ff015b;
            min-height: 50px;
            color: #fff;
            min-width: 250px;
            border: none;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 700;
            margin-top: 20px;
            margin-bottom: 20px;
            font-family: "CocoSharp-Bold";
        }

        h6 {
            font-size: 20px;
            font-weight: 700;
            font-family: "CocoSharp-Bold";
        }

        @media (max-width: 767px) {
            h1 {
                font-size: 30px;
            }

            button {
                font-size: 13px;
                min-height: 35px;
            }
        }
    }

    .cmn-head {
        color: #1f2024;
        font-family: "CocoSharp-Bold";
        font-size: 32px;
        font-weight: 800;
        text-align: center;
        margin-bottom: 20px;
    }

    .cmn-subhead {
        color: #6d7278;
        font-size: 16px;
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {
        .cmn-head {
            font-size: 25px;
        }
        .cmn-subhead {
            font-size: 15px;
        }
    }

    .product-sec {
        padding: 80px 15px;

        @media (max-width: 767px) {
            padding: 50px 15px;
        }
    }

    .prod-row {
        margin-top: 70px;

        .slick-arrow {
            display: none !important;
        }

        @include slider-dots;
        .slick-dots {
            bottom: -20px;
        }

        .prod-box {
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            font-family: "CocoSharp-Bold";
            // border: solid 1px grey;
            background: linear-gradient(to bottom, #4ef2e4, #22708a);
            padding: 1px;
            overflow: hidden;
            margin: 0 15px;
            margin-bottom: 30px;

            &.refleague {
                background: linear-gradient(to bottom, #47ec87, #34af8b);
            }

            &.reflive {
                background: linear-gradient(to bottom, #de8908, #dc0622);
            }

            &.refpay {
                background: linear-gradient(to bottom, #359fcc, #144272);
            }
            .wrap {
                min-height: 280px;
                background-color: #ffffff;
                text-align: center;
                // align-items: center;
                // justify-content: center;
                // display: flex;
                border-radius: 10px;
                padding: 30px 15px;
            }
            img {
                height: 50px;
                margin: 0 auto;
                margin-bottom: 15px;
            }

            h3 {
                color: #212341;
                font-size: 25px;
                font-weight: 800;
                margin-bottom: 20px;
            }

            p {
                color: #6d7278;
                font-family: "cocosharp-regular";
            }
        }
        @media (max-width: 991px) {
            .prod-box {
                .wrap {
                    min-height: 250px;
                }
            }
        }

        @media (max-width: 767px) {
            margin-top: 40px;
            .prod-box {
                .wrap {
                    min-height: 150px;
                }

                img {
                    height: 40px;
                }

                h3 {
                    font-size: 21px;
                }
                p {
                    border-top: solid 1px #ececec;
                    padding-top: 15px;
                }
            }
        }
    }

    .member-sec {
        padding: 50px 15px;

        .mem-row {
            margin-top: 50px;

            .slick-arrow {
                display: none !important;
            }
            @include slider-dots;
            .slick-dots {
                bottom: 0px;
            }
            .mem-box {
                text-align: center;
                padding: 0 15px;
                margin-bottom: 30px;
                img {
                    height: 80px;
                    margin: 0 auto;
                    margin-bottom: 15px;
                }

                h5 {
                    font-size: 17px;
                    font-weight: 700;
                    color: #1f2024;
                    margin-bottom: 20px;
                }

                p {
                    color: #6d7278;
                    font-size: 14px;
                }

                @media (max-width: 767px) {
                }
            }
        }
    }

    .feat-sec {
        padding: 100px 0;

        .cmn-head,
        .cmn-subhead {
            padding-left: 15px;
            padding-right: 15px;
        }
        .feat-slider-area {
            margin-top: 50px;
            // background: linear-gradient(270deg, #05ece1 0%, #2b6d89 100%);
            background-color: #000;

            .slick-prev,
            .slick-next {
                width: 70px;
                height: 145px;
                img {
                    width: 70px;
                }
            }

            .slick-prev {
                left: 0;
            }
            .slick-next {
                right: 0px;
            }

            .feat-slide {
                padding: 15px 100px;
                min-height: 400px;
                .row {
                    align-items: center;
                }
                .cap-box {
                    color: #fff;
                    padding: 15px 30px;
                    max-width: 500px;
                    h2 {
                        font-family: "Muli-ExtraBold";
                        font-size: 32px;
                        font-weight: 800;
                        background: -webkit-gradient(linear, left top, right top, from(#00b569), to(#0080ff));
                        background: -webkit-linear-gradient(left, #00b569, #0080ff);
                        background: -o-linear-gradient(left, #00b569, #0080ff);
                        background: linear-gradient(to right, #00b569, #0080ff);
                        background-clip: text;
                        color: transparent;
                        -webkit-background-clip: text;
                    }

                    p {
                        font-size: 18px;
                        line-height: 29px;
                    }
                }

                .r-main-ar {
                    text-align: center;
                }

                img.main-img {
                    max-width: 100%;
                    display: inline-block;
                }

                @media (max-width: 991px) {
                    .cap-box {
                        h2 {
                            font-size: 20px;
                        }
                        p {
                            font-size: 15px;
                            line-height: 18px;
                        }
                    }
                }

                @media (max-width: 767px) {
                    padding: 15px;
                    .cap-box {
                        padding: 15px;
                        text-align: center;
                        h2 {
                            font-size: 24px;
                        }
                        p {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }

                    .r-main-ar {
                        text-align: center;
                    }
                }
            }

            .slick-prev,
            .slick-next {
                font-style: normal;
                z-index: 2;
                font-size: 22px;
                cursor: pointer;
                color: #fff;
                &:hover {
                    color: #fff;
                }
                &:before {
                    content: "";
                }
            }

            @include slider-dots;
        }
    }

    .gl-ser-section {
        background: $primary;
        text-align: center;
        padding: 60px 0;

        .join-btn {
            height: 35px;
            width: 110px;
            border-radius: 17.5px;
            background-color: #ff015b;
            border: none;
            color: #fff;
        }

        .desc{
            max-width: 850px;
            margin: 0 auto;
            font-size: 16px;
            margin-bottom: 20px;
        }
    }

    .faq-section {
        padding: 80px 15px;
    }

    .feature-sec {
        overflow: hidden;
        padding-top: 80px;


        .cmn-head{
            margin-bottom: 150px;
        }
        .circle-wrap {
            width: 880px;
            // overflow: hidden;
            height: 100%;
            margin: 0 auto;
            position: relative;
            height: 350px;
            .bg-circle1 {
                width: 880px;
                height: 880px;
                position: absolute;
                border: 1px solid #37003c;
                opacity: 0.1;
                background-color: #03f5e7;
                border-radius: 50%;
                left: 0;
                z-index: 1;
            }
            .bg-circle2 {
                width: 520px;
                height: 520px;
                position: absolute;
                border: 1px solid #37003c;
                opacity: 0.1;
                background-color: #03f5e7;
                border-radius: 50%;
                left: calc(440px - 260px);
                top: 150px;
                z-index: 2;
            }

            .logo-fr {
                width: 170px;
                height: 170px;
                background-color: #03f5e7;
                border-radius: 50%;
                z-index: 3;
                position: absolute;
                bottom: -90px;
                left: calc(440px - 85px);
                // left: 350px;
                text-align: center;
                img {
                    width: 60px;
                    margin: 0 auto;
                    margin-top: 30px;
                }
            }

            .ft-ul {
                z-index: 5;
                position: relative;
                padding: 10px;
                height: 100%;

                &.mobile {
                    display: none;
                }

                &.desktop {
                    .ft-l {
                        position: absolute;

                        &.login-signup {
                            bottom: 50px;
                            left: -15px;
                            animation-name: bobble !important;
                            animation-duration: 8s;
                            animation-iteration-count: infinite;
                        }

                        &.fixture {
                            bottom: 65px;
                            left: 186px;
                            animation-name: bobble-2 !important;
                            animation-duration: 8s;
                            animation-iteration-count: infinite;
                        }

                        &.score-rank {
                            top: -98px;
                            left: 347px;
                            animation-name: bobble !important;
                            animation-duration: 8s;
                            animation-iteration-count: infinite;
                        }

                        &.redeem-prize {
                            bottom: 90px;
                            right: 215px;
                            animation-name: bobble-2 !important;
                            animation-duration: 8s;
                            animation-iteration-count: infinite;
                        }

                        &.awards {
                            bottom: 50px;
                            right: -15px;
                            animation-name: bobble !important;
                            animation-duration: 8s;
                            animation-iteration-count: infinite;
                        }

                        &.fixture,
                        &.redeem-prize {
                            .ft-box {
                                .img-circle {
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                        }
                    }
                }

                .ft-box {
                    text-align: center;

                    .img-circle {
                        width: 150px;
                        height: 150px;
                        background: #fff;
                        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto;
                        margin-top: 15px;
                        margin-bottom: 5px;
                        img {
                            height: 50px;
                        }
                    }

                    span {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 1;
                    }
                }
            }
        }

        @media (max-width: 991px) {

            .cmn-head{
                margin-bottom: 100px;
            }
            .circle-wrap {
                width: 700px;
                height: 315px;

                .bg-circle1 {
                    width: 700px;
                    height: 700px;
                }

                .bg-circle2 {
                    width: 400px;
                    height: 400px;
                    left: 150px;
                }

                .logo-fr {
                    width: 150px;
                    height: 150px;
                    left: 275px;
                    bottom: -75px;
                    img {
                        width: 40px;
                    }
                }

                .ft-ul {
                    &.desktop {
                        .ft-l {
                            &.fixture {
                                bottom: 60px;
                                left: 150px;
                            }

                            &.score-rank {
                                top: -73px;
                                left: 280px;
                            }

                            &.redeem-prize {
                                bottom: 80px;
                                right: 180px;
                            }
                        }
                    }
                    .ft-box {
                        .img-circle {
                            width: 110px;
                            height: 110px;
                            img {
                                height: 40px;
                            }
                        }
                        span {
                            font-size: 14px;
                            line-height: 14px;
                            br {
                                line-height: 0;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 767px) {
            padding-top: 50px;
            .cmn-head{
                margin-bottom: 50px;
            }
            .circle-wrap {
                width: 360px;
                height: 180px;

                .bg-circle1 {
                    width: 360px;
                    height: 360px;
                }

                .bg-circle2 {
                    width: 200px;
                    height: 200px;
                    left: 80px;
                    top: 80px;
                }

                .logo-fr {
                    display: none;
                }

                .ft-ul {
                    width: 250px;
                    margin: 0 auto;
                    padding-top: 25px;
                    &.desktop {
                        display: none;
                    }

                    &.mobile {
                        display: block;
                    }

                    .slick-next,
                    .slick-prev {
                        font-size: 20px;
                        &:before {
                            content: "";
                        }

                        span {
                            color: black;
                            width: 25px;
                            height: 25px;
                            background: #fff;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    .slick-prev {
                        left: 0px;
                    }

                    .slick-next {
                        right: 0px;
                    }

                    .ft-box {
                        .img-circle {
                            width: 90px;
                            height: 90px;
                            img {
                                height: 40px;
                            }
                        }
                        span {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        @media (max-width: 360px) {
            .circle-wrap {
                width: 300px;
                height: 150px;

                .bg-circle1 {
                    width: 300px;
                    height: 300px;
                }

                .bg-circle2 {
                    width: 150px;
                    height: 150px;
                    left: 75px;
                    top: 75px;
                }

                .ft-ul {
                    padding-top: 10px;
                }
            }
        }
    }
}

@keyframes bobble {
    0% {
        transform: translate3d(0px, 0px, 0px);
        animation-timing-function: ease-in;
    }
    50% {
        transform: translate3d(5px, 20px, 0px);
        animation-timing-function: ease-out;
    }
    100% {
        transform: translate3d(0px, 0px, 0px);
    }
}

@keyframes bobble-2 {
    0% {
        transform: translate3d(0px, 0px, 0px);
        animation-timing-function: ease-in;
    }
    50% {
        transform: translate3d(10px, 5px, 0px);
        animation-timing-function: ease-out;
    }
    100% {
        transform: translate3d(0px, 0px, 0px);
    }
}
