@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?9bbgrb');
  src: url('../fonts/icomoon.eot?9bbgrb#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9bbgrb') format('truetype'),
    url('../fonts/icomoon.woff?9bbgrb') format('woff'),
    url('../fonts/icomoon.svg?9bbgrb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: "\e91d";
}

.icon-standings:before {
  content: "\e91e";
}

.icon-edit-line:before {
  content: "\e91f";
}

.icon-share:before {
  content: "\e920";
}

.icon-add-circular:before {
  content: "\e92b";
}

.icon-bat:before {
  content: "\e919";
}

.icon-remove:before {
  content: "\e91a";
}

.icon-pitch:before {
  content: "\e902";
}

.icon-navigation:before {
  content: "\e907";
}

.icon-filter:before {
  content: "\e908";
}

.icon-left-arrow:before {
  content: "\e909";
}


.icon-right-arrow:before {
  content: "\e909";
  transform: rotate(180deg);
  display: inline-block;
}

.icon-close:before {
  content: "\e90a";
}

.icon-arrow-sort:before {
  content: "\e901";
}

.icon-next-btn:before {
  content: "\e900";
}

.icon-check:before {
  content: "\e903";
}

.icon-edit:before {
  content: "\e904";
}

.icon-facebook:before {
  content: "\e905";
}

.icon-g-plus:before {
  content: "\e906";
}

.icon-g:before {
  content: "\e90b";
}

.icon-m:before {
  content: "\e90c";
}

.icon-tick:before {
  content: "\e90d";
}

.icon-info:before {
  content: "\e913";
}

.icon-search:before {
  content: "\e912";
}

.icon-plus:before {
  content: "\e911";
}

.icon-minus-square:before {
  content: "\e910";
}

.icon-listview:before {
  content: "\e90f";
}

.icon-return:before {
  content: "\e90e";
}

.icon-arrow-up:before {
  content: "\e915";
}

.icon-arrow-down:before {
  content: "\e914";
}

.icon-tick-circular:before {
  content: "\e916";
}

.icon-eye-cancel:before {
  content: "\e918";
}

.icon-eye:before {
  content: "\e917";
}

.icon-home:before {
  content: "\e928";
}

.icon-Winner:before {
  content: "\e927";
}

.icon-trophy:before {
  content: "\e926";
}

.icon-Wallet:before {
  content: "\e925";
}

.icon-user:before {
  content: "\e924";
}

.icon-notification:before {
  content: "\e923";
}

.icon-vessel:before {
  content: "\e922";
}

.icon-question:before {
  content: "\e921";
}

.icon-file:before {
  content: "\e91b";
}

.icon-league-code:before {
  content: "\e929";
}

.icon-logout:before {
  content: "\e92a";
}

.icon-add-circular:before {
  content: "\e92b";
}

.icon-reload:before {
  content: "\e91c";
}

.icon-trophy-ic:before {
  content: "\e92e";
}

.icon-mail:before {
  content: "\e92d";
}

.icon-whatsapp:before {
  content: "\e931";
}

.icon-email:before {
  content: "\e930";
}

.icon-link:before {
  content: "\e92f";
}

.icon-ruppee:before {
  content: "\e932";
}

.icon-credit:before {
  content: "\e934";
}

.icon-debit:before {
  content: "\e933";
}

.icon-calendar-ic:before {
  content: "\e935";
}

.icon-coins:before {
  content: "\e936";
}

.icon-thumbs-up:before {
  content: "\e938";
}

.icon-thumbs-down:before {
  content: "\e937";
}

.icon-clock:before {
  content: "\e939";
}

.icon-bell:before {
  content: "\e93a";
}

.icon-cross-circular:before {
  content: "\e93c";
}

.icon-circular-menu:before {
  content: "\e93b";
}

.icon-my-contests:before {
  content: "\e941";
}

.icon-refer:before {
  content: "\e940";
}

.icon-wall:before {
  content: "\e93f";
}

.icon-wallet:before {
  content: "\e93e";
}

.icon-more:before {
  content: "\e93d";
}

.icon-boxing:before {
  content: "\e945";
}

.icon-tennis:before {
  content: "\e944";
}

.icon-football:before {
  content: "\e943";
}

.icon-badminton:before {
  content: "\e942";
}

.icon-redeem-coins:before {
  content: "\e94a";
}

.icon-envelop:before {
  content: "\e949";
}

.icon-leaderboard:before {
  content: "\e948";
}

.icon-message:before {
  content: "\e947";
}

.icon-careers:before {
  content: "\e946";
}

.icon-download:before {
  content: "\e94b";
}

.icon-twitter:before {
  content: "\e950";
}

.icon-fire:before {
  content: "\e94f";
}

.icon-dislike:before {
  content: "\e94e";
}

.icon-comment:before {
  content: "\e94d";
}

.icon-instagram:before {
  content: "\e94c";
}

.icon-like:before {
  content: "\e951";
}
.icon-admin:before {
  content: "\e952";
}
.icon-reply:before {
  content: "\e954";
}
.icon-like-empty:before {
  content: "\e953";
}
.icon-linkdin:before {
  content: "\e955";
}
.icon-switch-team:before {
  content: "\e956";
}
.icon-history:before {
  content: "\e95a";
}
.icon-rules:before {
  content: "\e959";
}
.icon-redeem:before {
  content: "\e958";
}
.icon-lock:before {
  content: "\e957";
}
.icon-download-circle:before {
  content: "\e95b";
}
.icon-bonus:before {
  content: "\e95c";
}
.icon-add-user:before {
  content: "\e934";
}
.icon-user-select:before {
  content: "\e92c";
}
.icon-send:before {
  content: "\e933";
}
.icon-facebook-2:before {
  content: "\e944";
}
.icon-twitter-2:before {
  content: "\e942";
}
.icon-dots:before {
  content: "\e936";
}
.icon-note:before {
  content: "\e95c";
}
.icon-star:before {
  content: "\e945";
}
.icon-website:before {
  content: "\e95f";
}
.icon-calender-2:before {
  content: "\e95e";
}
.icon-verses:before {
  content: "\e96a";
}
.icon-leaderboard-ic:before {
  content: "\e969";
}
.icon-performance:before {
  content: "\e968";
}
.icon-stats:before {
  content: "\e967";
}
.icon-achievement:before {
  content: "\e966";
}
.icon-training:before {
  content: "\e965";
}
.icon-refpodcast:before {
  content: "\e961";
}
.icon-blog:before {
  content: "\e960";
}
.icon-result:before {
  content: "\e962";
}
.icon-refer-ic:before {
  content: "\e963";
}
.icon-circle-tick:before {
  content: "\e96c";
}
.icon-transfer:before {
  content: "\e96b";
}
.icon-naira:before {
  content: "\e964";
}
.icon-refresh:before {
  content: "\e96e";
}
.icon-edit-pen:before {
  content: "\e96d";
}
.icon-logo:before {
  content: "\e96f";
}
.icon-attach:before {
  content: "\e973";
}
.icon-record:before {
  content: "\e972";
}
.icon-recorder:before {
  content: "\e971";
}
.icon-picture:before {
  content: "\e970";
}
.icon-sorting-arrow-down:before {
  content: "\e975";
}
.icon-sorting-arrow-up:before {
  content: "\e974";
}
.icon-stop:before {
  content: "\e976";
}
.icon-setting:before {
  content: "\e977";
}
.icon-nav-ic:before {
  content: "\e907";
}
.icon-camera:before {
  content: "\e979";
}
.icon-calender:before {
  content: "\e97a";
}
.icon-calender2:before {
  content: "\e95e";
}
.icon-infinite:before {
  content: "\e978";
}
.icon-delete:before {
  content: "\e97a";
}

.icon-arrow-left:before {
  content: "\e914";
  transform: rotate(90deg);
  display: inline-block;
}

.icon-arrow-right:before {
  content: "\e914";
  transform: rotate(270deg);
  display: inline-block;
}

.icon-whatsapp {
  color: $teal;
}

.icon-facebook {
  color: $fb-color;
}

.icon-twitter{
  color: $twitter-color;
}

.icon-notifications-ic:before {
  content: "\e97c";
}
.icon-lobby:before {
  content: "\e97b";
}

.icon-activity:before {
  content: "\e97e";
}
.icon-message1:before {
  content: "\e97d";
}

.icon-logo-new:before {
  content: "\e96f";
}

.icon-Rits:before {
  content: "\e98d";
}
.icon-compare:before {
  content: "\e98c";
}
.icon-bell1:before {
  content: "\e98b";
}
.icon-my-contest-1:before {
  content: "\e98a";
}
.icon-inbox:before {
  content: "\e989";
}
.icon-view-visible:before {
  content: "\e987";
}
.icon-toggle-vertical:before {
  content: "\e988";
}

.icon-currency:before {
  content: "\e98e";
}
.icon-transaction:before {
  content: "\e98f";
}

.icon-insta-ic:before {
  content: "\e995";
}
.icon-whatsapp-ic:before {
  content: "\e996";
}
.icon-youtube-ic:before {
  content: "\e997";
}
.icon-twitter-ic:before {
  content: "\e998";
}
.icon-fb-ic:before {
  content: "\e999";
}

.icon-logo-2:before {
  content: "\e99f";
}