// stylelint-disable selector-no-qualifying-type

//
// Base styles
//
@media (min-width:1024px){
  button:focus,
  :focus {
    outline: 0;
    box-shadow: none;
  }
}
button:focus,
  :focus {
    outline: 0;
  }
.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  text-transform: uppercase;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size-base, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    // opacity: $btn-disabled-opacity;
    box-shadow: none !important;
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {

    &:focus {
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @if $color=='primary' {
      @include button-variant($value, $value, $cyan);
    } @else {
      @include button-variant($value, $value);
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $gray-900;
  background: transparent !important;

  @include hover {
    // color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
    box-shadow: none !important;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}


//
// Button Rounded
//

.btn-rounded{
  border-radius: 15px;
  padding: 2px;
  // background: linear-gradient(132.3deg, $ternary 0%, $primary 100%);
  background: $primary;
  font-size: 11px;
  line-height: 1.3;
  color: $refsocial-secondary-clr;
}
.btn-light-rounded{
  @extend .btn-rounded;
  background: linear-gradient(90deg, #f8f9fa 0%, #f8f9fa 60%, #f8f9fa 90%);
  color: $dark-blue;
  padding: 15px;
  border-radius: 50px;
  font-size: 13px;
}
.btn-primary{
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2), 0 5px 10px 0 rgba(0,0,0,0.1);
  font-family: $font-black;
  // background: linear-gradient(75.01deg,$ternary 0%, $primary 100%);
  background:$primary;
  color: $refsocial-secondary-clr;
  &:hover, &:focus{
    background:$primary;
    color: $black;
  }
  &:disabled {
    background: linear-gradient(90deg, #E5E5E5 0%, #E5E5E5 60%, #E5E5E5 90%);
    box-shadow: none !important;
    &:hover,&:focus{
      background: linear-gradient(90deg, #E5E5E5 0%, #E5E5E5 60%, #E5E5E5 90%);
      color: #A0A0A0;
    }
  }
}
.btn-rounded{
  border-radius: 15px;
  font-family: $font-black;
  padding: 8px 10px;
  &.btn-success{
    background: $green;
    color: $white;
    &:disabled {
      background: linear-gradient(90deg, #E5E5E5 0%, #E5E5E5 60%, #E5E5E5 90%);
      box-shadow: none !important;
    }
  }
  &.btn-danger{
    background: $dark-red;
    color: $white;
  }
  &.btn-reflive{
    background: $reflive-clr;
  }
  &:disabled {
    background: linear-gradient(90deg, #E5E5E5 0%, #E5E5E5 60%, #E5E5E5 90%);
    box-shadow: none !important;
    color: grey;
  }
}
.btn-rounded-border{
  border-radius: 15px;
  border: 2px solid $primary;
  background: transparent;
  color: $gray-900;
  padding: 4px 15px;
  font-family: $font-black;
  
}
.btn-xlg{
  padding: 15px !important;
  font-size: 14px;
  font-family: $font-black;
  border-radius: 22px;
}
a{
  &:hover,&:focus,&:active,&.active{
    color: $secondary !important;
  }
}
.refpay-web-container{
  .btn-rounded,.btn-primary{
    background: linear-gradient(62.15deg, $refpay-secondary-clr 0%, $refpay-clr 100%);
    &:disabled {
      background: linear-gradient(90deg, #E5E5E5 0%, #E5E5E5 60%, #E5E5E5 90%);
      box-shadow: none !important;
      &:hover,&:focus{
        background: linear-gradient(90deg, #E5E5E5 0%, #E5E5E5 60%, #E5E5E5 90%);
        color: #A0A0A0;
      }
    }
  }
}
.btn-lg{
  padding: 15px 30px !important;
  font-size: 14px;
  border-radius: 22px;
}